body {
	padding: 0;
	margin: 0;
	font-family: $font-primary;
	background-color: #F0F4F9;
	color: #222;
}

a {
	color: $primary;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $secondary;
	}
}
