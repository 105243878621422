#dashboard-header {
  background-color: #FFF;
  padding: 2px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 3px -1px;
  position: relative;
  z-index: 1;
}

.header-left {
  display: flex;
  align-items: center;

  .working-company {
    display: flex;
    align-items: center;
    margin-left: 20px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 20px;

    svg {
      width: 24px;
      margin-top: 5px;
      margin-left: 6px;
    }
  }
}