

.filters-wrap {

    .ant-form {
        display: flex;
        flex-wrap: wrap;

        .ant-form-item {
            margin-right: 10px;
        }
    }

}

.ant-select-selector {
    border-radius: $radius  !important;
}