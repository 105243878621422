//colors
$primary: #2046CF;
$secondary: #001B66;
$green: #28a745;
$red: #dc3545;
$themeBlack: #1C252C;
$themeLight: #F3F6FD;
$themeDark: #253250;
$lightGray: #FAFAFA;
$warning: #FFCD4E;
$cyan: #4FC4C4;
$gray: #8D8D8D;
$black: #1C252C;

$orange: #E46226;

$light-background: #FFF;
$light-text: #1F2026;
$dark-background: #17181a;
$dark-text: rgba(255, 255, 255, 0.8);

$smgray: #D1D1D1;
$white: white;
// fonts

$font-primary: "Work Sans", sans-serif;

$radius: 8px;