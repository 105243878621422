.form-default {
  .ant-form-item {
    margin-bottom: 20px;

    .ant-form-item-label {
      padding: 0 0 4px 0;
    }
  }
}

.form-compact {
  .ant-form-item {
    margin-bottom: 10px;

    .ant-form-item-label {
      padding: 0 0 4px 0;
    }
  }
}

.form-section {
  border: 1px solid rgba(0, 0, 0, 0.116);
  padding: 44px;
  border-radius: 12px;


  .form-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.116);
    padding: 22px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }


    &.bb-none {
      border-bottom: none;
    }


    .left-form-tittle {

      max-width: 300px;

      .count {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F0F4F9;
        border-radius: 50%;
        font-weight: bold;
        color: $primary;
        margin-bottom: 20px;
      }

      h3 {
        color: $primary;
      }

      svg {
        color: $primary;
        font-size: 24px;
      }

      h2 {
        margin: 0;
        font-weight: 500;
        font-size: 18px;
      }

      p {
        margin: 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.555);
      }
    }
  }
}



.upload-box {
  label {
    display: block;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: $radius;
    padding: 20px;
    border: 1px dashed rgba(0, 0, 0, 0.3);
    text-align: center;
    cursor: pointer;
    .upload-icon {
      svg {
        width: 50px;
        height: 50px;
        color: $primary;
      }
    }

    .upload-text {
      font-size: 16px;
    }

    .upload-hint {
      color: rgba(0, 0, 0, 0.6);
    }


    input {
      opacity: 0;
      position: absolute;
      left: 0;
    }

  }
}