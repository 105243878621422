.side-bar {
    list-style-type: none;
    margin: 0;
    padding: 0;
  
    li {
      margin: 0;
      padding: 0;
      margin-bottom: 20px;
  
      &:last-child {
        margin-bottom: 0;
      }
  
      a {
        // padding: 15px 12px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        color: rgba(0,0,0,0.7);
        &.active,
        &.fouse,
        &.hover {
          i {
            background-color: rgba(0,0,0,0.9);
            border-radius: 6px;
          }
        }
  
        svg {
          width: 20px;
        }
  
        i {
          display: block;
          padding: 2px 12px;
  
          &:hover,
          &.active {
            background-color: rgba(0,0,0,0.8);
            color: $primary;
            border-radius: 6px;
            text-align: center;
          }
        }
  
        span {
          text-align: center;
          font-size: 10px;
          font-weight: 500;
          margin-top: 2px;
        }
      }
    }
  
  
  
    i {
      margin-right: 0px;
    }
  
    .sidebar-label {
      flex-grow: 1;
    }
  
    .submenu {
      display: none;
      background: #f0f0f0;
      padding: 10px;
  
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
    }
  }
  
  .toggle-input {
    display: none;
  }
  
  
  // .toggle-input:checked + .sidebar-item .submenu {
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   left: 100%;
  //   background-color: black;
  // }