@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
.list-actions {
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-radius: 8px;
  background-color: #D2ECF9;
  overflow: hidden;
  width: 100%;
}
.list-actions li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #1C252C;
  font-weight: 500;
}
.list-actions li a:hover {
  background-color: #A5D9F3;
}
.list-actions li a svg {
  width: 24px;
}
.list-actions li:last-child a {
  border-bottom: none;
}

.inline-action {
  display: flex;
  margin: 0;
  padding: 0px;
}
.inline-action li {
  padding: 0px 4px;
  list-style: none !important;
}
.inline-action li button,
.inline-action li a {
  border-radius: 50% !important;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
}
.inline-action li .primary {
  border: 1px solid #2046CF;
  background: #2046CF;
  color: #ffffff;
}
.inline-action li .primary:hover {
  border: 1px solid #2046CF;
  background: transparent;
  color: #2046CF;
}
.inline-action li .green {
  border: 1px solid #04D9A1;
  background: #04D9A1;
  color: #ffffff;
}
.inline-action li .green:hover {
  border: 1px solid #04D9A1;
  background: transparent;
  color: #04D9A1;
}
.inline-action li .blue {
  border: 1px solid #05acff;
  background: #05acff;
  color: #ffffff;
}
.inline-action li .blue:hover {
  border: 1px solid #05acff;
  background: transparent;
  color: #05acff;
}
.inline-action li .red {
  border: 1px solid #ff002b;
  background: #ff002b;
  color: #ffffff;
}
.inline-action li .red:hover {
  border: 1px solid #ff002b;
  background: transparent;
  color: #ff002b;
}

.container {
  max-width: 1170px;
  margin: auto;
}

.button-user-in {
  display: inline-flex;
  align-items: center;
  background-color: #FFF;
  padding: 8px;
  border-radius: 80px;
}
.button-user-in .short-name {
  width: 32px;
  height: 32px;
  background-color: #2046CF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #FFF;
  font-size: 13px;
  font-weight: 500;
  margin-right: 8px;
  text-transform: uppercase;
}
.button-user-in span {
  white-space: nowrap;
  margin-right: 8px;
  text-transform: capitalize;
}
.button-user-in svg {
  width: 18px;
}

.text-center {
  text-align: center;
}

.form-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.invisible {
  visibility: hidden !important;
}

.visible {
  visibility: visible !important;
}

@media (max-width: 575px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 576px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
.p-0 {
  padding: 0 !important;
}

@media (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
.p-1 {
  padding: 0.25rem !important;
}

@media (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
.p-2 {
  padding: 0.5rem !important;
}

@media (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
.p-3 {
  padding: 1rem !important;
}

@media (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
.p-4 {
  padding: 1.5rem !important;
}

@media (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
.p-5 {
  padding: 2rem !important;
}

@media (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

@media (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

@media (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
.pt-0 {
  padding-top: 0 !important;
}

@media (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
.pt-1 {
  padding-top: 0.25rem !important;
}

@media (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
.pt-2 {
  padding-top: 0.5rem !important;
}

@media (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
.pt-3 {
  padding-top: 1rem !important;
}

@media (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
.pt-4 {
  padding-top: 1.5rem !important;
}

@media (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
.pt-5 {
  padding-top: 2rem !important;
}

@media (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
.pr-0 {
  padding-right: 0 !important;
}

@media (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
.pr-1 {
  padding-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
.pr-2 {
  padding-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
.pr-3 {
  padding-right: 1rem !important;
}

@media (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
.pr-4 {
  padding-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
.pr-5 {
  padding-right: 2rem !important;
}

@media (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
.pb-0 {
  padding-bottom: 0 !important;
}

@media (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
.pb-3 {
  padding-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
.pb-5 {
  padding-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
.pl-0 {
  padding-left: 0 !important;
}

@media (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
.pl-1 {
  padding-left: 0.25rem !important;
}

@media (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
.pl-2 {
  padding-left: 0.5rem !important;
}

@media (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
.pl-3 {
  padding-left: 1rem !important;
}

@media (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
.pl-4 {
  padding-left: 1.5rem !important;
}

@media (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
.pl-5 {
  padding-left: 2rem !important;
}

@media (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
.m-0 {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
.m-1 {
  margin: 0.25rem !important;
}

@media (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
.m-2 {
  margin: 0.5rem !important;
}

@media (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
.m-3 {
  margin: 1rem !important;
}

@media (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
.m-4 {
  margin: 1.5rem !important;
}

@media (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
.m-5 {
  margin: 2rem !important;
}

@media (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

@media (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

@media (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
.mt-0 {
  margin-top: 0 !important;
}

@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
.mt-1 {
  margin-top: 0.25rem !important;
}

@media (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
.mt-2 {
  margin-top: 0.5rem !important;
}

@media (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
.mt-3 {
  margin-top: 1rem !important;
}

@media (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
.mt-4 {
  margin-top: 1.5rem !important;
}

@media (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
.mt-5 {
  margin-top: 2rem !important;
}

@media (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
.mr-0 {
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
.mr-1 {
  margin-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
.mr-2 {
  margin-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
.mr-3 {
  margin-right: 1rem !important;
}

@media (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
.mr-4 {
  margin-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
.mr-5 {
  margin-right: 2rem !important;
}

@media (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
.mb-3 {
  margin-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
.mb-5 {
  margin-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
.ml-0 {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
.ml-1 {
  margin-left: 0.25rem !important;
}

@media (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
.ml-2 {
  margin-left: 0.5rem !important;
}

@media (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
.ml-3 {
  margin-left: 1rem !important;
}

@media (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
.ml-4 {
  margin-left: 1.5rem !important;
}

@media (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
.ml-5 {
  margin-left: 2rem !important;
}

@media (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
.p-0 {
  padding: 0 !important;
}

@media (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
.p-1 {
  padding: 0.25rem !important;
}

@media (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
.p-2 {
  padding: 0.5rem !important;
}

@media (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
.p-3 {
  padding: 1rem !important;
}

@media (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
.p-4 {
  padding: 1.5rem !important;
}

@media (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
.p-5 {
  padding: 2rem !important;
}

@media (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

@media (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

@media (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
.pt-0 {
  padding-top: 0 !important;
}

@media (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
.pt-1 {
  padding-top: 0.25rem !important;
}

@media (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
.pt-2 {
  padding-top: 0.5rem !important;
}

@media (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
.pt-3 {
  padding-top: 1rem !important;
}

@media (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
.pt-4 {
  padding-top: 1.5rem !important;
}

@media (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
.pt-5 {
  padding-top: 2rem !important;
}

@media (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
.pr-0 {
  padding-right: 0 !important;
}

@media (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
.pr-1 {
  padding-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
.pr-2 {
  padding-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
.pr-3 {
  padding-right: 1rem !important;
}

@media (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
.pr-4 {
  padding-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
.pr-5 {
  padding-right: 2rem !important;
}

@media (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
.pb-0 {
  padding-bottom: 0 !important;
}

@media (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
.pb-3 {
  padding-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
.pb-5 {
  padding-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
.pl-0 {
  padding-left: 0 !important;
}

@media (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
.pl-1 {
  padding-left: 0.25rem !important;
}

@media (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
.pl-2 {
  padding-left: 0.5rem !important;
}

@media (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
.pl-3 {
  padding-left: 1rem !important;
}

@media (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
.pl-4 {
  padding-left: 1.5rem !important;
}

@media (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
.pl-5 {
  padding-left: 2rem !important;
}

@media (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
.m-0 {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
.m-1 {
  margin: 0.25rem !important;
}

@media (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
.m-2 {
  margin: 0.5rem !important;
}

@media (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
.m-3 {
  margin: 1rem !important;
}

@media (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
.m-4 {
  margin: 1.5rem !important;
}

@media (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
.m-5 {
  margin: 2rem !important;
}

@media (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

@media (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

@media (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
.mt-0 {
  margin-top: 0 !important;
}

@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
.mt-1 {
  margin-top: 0.25rem !important;
}

@media (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
.mt-2 {
  margin-top: 0.5rem !important;
}

@media (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
.mt-3 {
  margin-top: 1rem !important;
}

@media (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
.mt-4 {
  margin-top: 1.5rem !important;
}

@media (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
.mt-5 {
  margin-top: 2rem !important;
}

@media (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
.mr-0 {
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
.mr-1 {
  margin-right: 0.25rem !important;
}

@media (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
.mr-2 {
  margin-right: 0.5rem !important;
}

@media (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
.mr-3 {
  margin-right: 1rem !important;
}

@media (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
.mr-4 {
  margin-right: 1.5rem !important;
}

@media (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
.mr-5 {
  margin-right: 2rem !important;
}

@media (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}

@media (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
.mb-3 {
  margin-bottom: 1rem !important;
}

@media (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}

@media (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
.mb-5 {
  margin-bottom: 2rem !important;
}

@media (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
.ml-0 {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
.ml-1 {
  margin-left: 0.25rem !important;
}

@media (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
.ml-2 {
  margin-left: 0.5rem !important;
}

@media (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
.ml-3 {
  margin-left: 1rem !important;
}

@media (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
.ml-4 {
  margin-left: 1.5rem !important;
}

@media (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
.ml-5 {
  margin-left: 2rem !important;
}

@media (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .p-0 {
    padding: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .p-1 {
    padding: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .p-2 {
    padding: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .p-3 {
    padding: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .p-4 {
    padding: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .p-5 {
    padding: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .pt-0 {
    padding-top: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .pt-1 {
    padding-top: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .pt-2 {
    padding-top: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pt-3 {
    padding-top: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .pt-4 {
    padding-top: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pt-5 {
    padding-top: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .pr-0 {
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .pr-1 {
    padding-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .pr-2 {
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pr-3 {
    padding-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .pr-4 {
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pr-5 {
    padding-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .pb-0 {
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pb-3 {
    padding-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pb-5 {
    padding-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .pl-0 {
    padding-left: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .pl-1 {
    padding-left: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .pl-2 {
    padding-left: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pl-3 {
    padding-left: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .pl-4 {
    padding-left: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .pl-5 {
    padding-left: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .m-0 {
    margin: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .m-1 {
    margin: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .m-2 {
    margin: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .m-3 {
    margin: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .m-4 {
    margin: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .m-5 {
    margin: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .mt-0 {
    margin-top: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .mt-1 {
    margin-top: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .mt-2 {
    margin-top: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mt-3 {
    margin-top: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .mt-4 {
    margin-top: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mt-5 {
    margin-top: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .mr-0 {
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .mr-1 {
    margin-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .mr-2 {
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mr-3 {
    margin-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .mr-4 {
    margin-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mr-5 {
    margin-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .mb-0 {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mb-3 {
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .mb-5 {
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 575.98px) {
  .ml-0 {
    margin-left: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .ml-1 {
    margin-left: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
@media (max-width: 575.98px) {
  .ml-2 {
    margin-left: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .ml-3 {
    margin-left: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
@media (max-width: 575.98px) {
  .ml-4 {
    margin-left: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
@media (max-width: 575.98px) {
  .ml-5 {
    margin-left: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (max-width: 575.98px) and (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .p-0 {
    padding: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .p-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .p-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .p-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .p-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .p-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pt-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pt-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pt-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pt-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pt-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pt-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pr-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pr-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pr-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pr-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pr-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pr-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pb-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pb-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pb-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pl-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pl-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pl-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pl-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pl-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pl-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .m-0 {
    margin: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .m-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .m-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .m-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .m-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .m-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mr-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mr-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mr-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mr-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mr-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mr-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ml-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ml-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ml-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ml-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ml-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .ml-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) and (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .p-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .p-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .p-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .p-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .p-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .p-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pt-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pr-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pr-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pr-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pr-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pr-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pr-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pb-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pb-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pb-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pl-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pl-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pl-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pl-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pl-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .pl-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mr-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mr-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mr-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mr-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mr-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mr-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .mb-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) and (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p-0 {
    padding: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pt-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pt-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pt-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pt-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pt-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pt-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pr-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pr-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pr-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pr-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pr-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pr-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pb-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pb-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pb-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pl-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pl-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pl-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pl-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pl-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pl-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .m-0 {
    margin: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .m-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .m-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .m-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .m-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .m-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mt-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mt-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mt-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mt-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mt-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mt-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mr-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mb-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mb-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .mb-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ml-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ml-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ml-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ml-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ml-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ml-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) and (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .p-0 {
    padding: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }
}
@media (min-width: 1200px) {
  .p-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .p-sm-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .p-md-1 {
    padding: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .p-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .p-sm-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .p-md-2 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .p-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .p-sm-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .p-md-3 {
    padding: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .p-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .p-sm-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .p-md-4 {
    padding: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .p-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .p-sm-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .p-md-5 {
    padding: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  .px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}
@media (min-width: 1200px) {
  .pt-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pt-md-3 {
    padding-top: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pt-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pt-md-5 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .pr-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  .pr-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .pr-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pr-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pr-md-3 {
    padding-right: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .pr-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pr-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pr-sm-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pr-md-5 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pb-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .pl-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important;
  }
}
@media (min-width: 1200px) {
  .pl-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .pl-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pl-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pl-md-3 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .pl-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .pl-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .pl-sm-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .pl-md-5 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .m-0 {
    margin: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
}
@media (min-width: 1200px) {
  .m-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .m-sm-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .m-md-1 {
    margin: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .m-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .m-sm-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .m-md-2 {
    margin: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .m-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .m-sm-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .m-md-3 {
    margin: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .m-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .m-sm-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .m-md-4 {
    margin: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .m-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .m-sm-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .m-md-5 {
    margin: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
}
@media (min-width: 1200px) {
  .mt-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mt-md-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mt-md-5 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .mr-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mr-sm-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mr-md-0 {
    margin-right: 0 !important;
  }
}
@media (min-width: 1200px) {
  .mr-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .mr-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mr-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mr-md-3 {
    margin-right: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .mr-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mr-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mr-sm-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mr-md-5 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 1200px) {
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .mb-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) {
  .ml-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .ml-sm-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .ml-md-0 {
    margin-left: 0 !important;
  }
}
@media (min-width: 1200px) {
  .ml-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
}
@media (min-width: 1200px) {
  .ml-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 1200px) {
  .ml-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .ml-md-3 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 1200px) {
  .ml-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .ml-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 576px) {
  .ml-sm-5 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .ml-md-5 {
    margin-left: 2rem !important;
  }
}
.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

@media (min-width: 576px) {
  .text-sm-center {
    text-align: center;
  }
  .text-sm-start {
    text-align: start;
  }
  .text-sm-end {
    text-align: end;
  }
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-justify {
    text-align: justify;
  }
}
@media (min-width: 768px) {
  .text-md-center {
    text-align: center;
  }
  .text-md-start {
    text-align: start;
  }
  .text-md-end {
    text-align: end;
  }
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-justify {
    text-align: justify;
  }
}
@media (min-width: 992px) {
  .text-lg-center {
    text-align: center;
  }
  .text-lg-start {
    text-align: start;
  }
  .text-lg-end {
    text-align: end;
  }
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-justify {
    text-align: justify;
  }
}
@media (min-width: 1200px) {
  .text-xl-center {
    text-align: center;
  }
  .text-xl-start {
    text-align: start;
  }
  .text-xl-end {
    text-align: end;
  }
  .text-xl-left {
    text-align: left;
  }
  .text-xl-right {
    text-align: right;
  }
  .text-xl-justify {
    text-align: justify;
  }
}
.visible {
  display: block !important;
}

.invisible {
  display: none !important;
}

@media (max-width: 575px) {
  .visible-xs {
    display: block !important;
  }
  .invisible-xs {
    display: none !important;
  }
}
@media (min-width: 576px) {
  .visible-sm {
    display: block !important;
  }
  .invisible-sm {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .visible-md {
    display: block !important;
  }
  .invisible-md {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .visible-lg {
    display: block !important;
  }
  .invisible-lg {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .visible-xl {
    display: block !important;
  }
  .invisible-xl {
    display: none !important;
  }
}
body {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", sans-serif;
  background-color: #F0F4F9;
  color: #222;
}

a {
  color: #2046CF;
  text-decoration: none;
}
a:hover, a:focus, a:active {
  color: #001B66;
}

.form-default .ant-form-item {
  margin-bottom: 20px;
}
.form-default .ant-form-item .ant-form-item-label {
  padding: 0 0 4px 0;
}

.form-compact .ant-form-item {
  margin-bottom: 10px;
}
.form-compact .ant-form-item .ant-form-item-label {
  padding: 0 0 4px 0;
}

.form-section {
  border: 1px solid rgba(0, 0, 0, 0.116);
  padding: 44px;
  border-radius: 12px;
}
.form-section .form-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.116);
  padding: 22px 0;
}
.form-section .form-header:first-child {
  padding-top: 0;
}
.form-section .form-header:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.form-section .form-header.bb-none {
  border-bottom: none;
}
.form-section .form-header .left-form-tittle {
  max-width: 300px;
}
.form-section .form-header .left-form-tittle .count {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F0F4F9;
  border-radius: 50%;
  font-weight: bold;
  color: #2046CF;
  margin-bottom: 20px;
}
.form-section .form-header .left-form-tittle h3 {
  color: #2046CF;
}
.form-section .form-header .left-form-tittle svg {
  color: #2046CF;
  font-size: 24px;
}
.form-section .form-header .left-form-tittle h2 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}
.form-section .form-header .left-form-tittle p {
  margin: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.555);
}

.upload-box label {
  display: block;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 20px;
  border: 1px dashed rgba(0, 0, 0, 0.3);
  text-align: center;
  cursor: pointer;
}
.upload-box label .upload-icon svg {
  width: 50px;
  height: 50px;
  color: #2046CF;
}
.upload-box label .upload-text {
  font-size: 16px;
}
.upload-box label .upload-hint {
  color: rgba(0, 0, 0, 0.6);
}
.upload-box label input {
  opacity: 0;
  position: absolute;
  left: 0;
}

.card {
  background-color: #FFF;
  border-radius: 8px;
}
.card.card-default {
  padding: 30px;
}

.ant-card {
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 16px 0px rgba(0, 0, 0, 0.02);
}

.ant-table-custom.table-compact table th {
  padding: 8px 10px;
}
.ant-table-custom.table-compact table td {
  padding: 8px 10px;
}
.ant-table-custom table {
  table-layout: auto;
  width: 100%;
  text-align: start;
  border-radius: 14px 14px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-custom table th {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 16px;
  overflow-wrap: break-word;
  font-size: 14px;
}
.ant-table-custom table th::before {
  position: absolute;
  top: 50%;
  inset-inline-end: 0;
  width: 1px;
  height: 1.6em;
  background-color: #f0f0f0;
  transform: translateY(-50%);
  transition: background-color 0.2s;
  content: "";
}
.ant-table-custom table td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  text-align: start;
  background: #FFF;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 16px;
  overflow-wrap: break-word;
  font-size: 14px;
}
.ant-table-custom table thead tr:first-child > *:first-child {
  border-start-start-radius: 14px;
}
.ant-table-custom table thead tr:first-child > *:last-child {
  border-start-end-radius: 14px;
}

.table-form input {
  border: none;
}
.table-form .ant-form-item {
  margin-bottom: 0;
}
.table-form .ant-input {
  padding: 4px 0;
}
.table-form .ant-select-selector {
  padding: 0 !important;
}
.table-form button {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.table-form button svg {
  width: 24px;
}

.delete svg {
  width: 18px;
  cursor: pointer;
}

.table-actions {
  display: flex;
}
.table-actions a {
  margin-right: 14px;
  color: #1C252C;
}
.table-actions svg {
  width: 16px;
  height: 16px;
}

.filters-wrap .ant-form {
  display: flex;
  flex-wrap: wrap;
}
.filters-wrap .ant-form .ant-form-item {
  margin-right: 10px;
}

.ant-select-selector {
  border-radius: 8px !important;
}

.auth-wrap {
  display: flex;
  background-color: #FFF;
}
.auth-wrap .left-col {
  width: 400px;
  flex-shrink: 0;
  background-color: #000;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.auth-wrap .left-col h3 {
  color: #FFF;
  font-weight: 400;
  max-width: 300px;
  margin: 40px auto 0 auto;
}
.auth-wrap .right-col {
  padding: 50px;
  width: 100%;
}
.auth-wrap .right-col .header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.auth-wrap .right-col .header p {
  margin: 0 10px 0 0;
}

.auth-form-wrap {
  max-width: 500px;
  margin: auto;
}

.header-auth {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

#dashboard-header {
  background-color: #FFF;
  padding: 2px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 3px -1px;
  position: relative;
  z-index: 1;
}

.header-left {
  display: flex;
  align-items: center;
}
.header-left .working-company {
  display: flex;
  align-items: center;
  margin-left: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
}
.header-left .working-company svg {
  width: 24px;
  margin-top: 5px;
  margin-left: 6px;
}

main#main {
  overflow: hidden;
  height: 100vh;
}

.wrapper {
  display: flex;
  width: 100%;
  background-color: #FFF;
  height: 100vh;
  overflow: hidden;
}
.wrapper .left-side {
  height: 100vh;
  width: 60px;
  padding: 20px 10px;
  border-right: 1px solid #EAF1FB;
  background-color: #EAF1FB;
}
.wrapper .left-side .sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wrapper .right-side {
  width: 100%;
  display: flex;
}
.wrapper .right-side .right-side-contents {
  width: 100%;
  padding: 0;
}
.wrapper .right-side .right-side-contents .page-content {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 90px);
  padding: 20px 28px;
}
.wrapper .right-side .sub-menu-wrap {
  width: 216px;
  border-right: 1px solid #E2E8F0;
  background-color: #F6F8FC;
  height: 100vh;
  padding: 20px 0;
  flex-shrink: 0;
}
.wrapper .right-side .sub-menu-wrap h5 {
  margin: 0 14px;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
}
.wrapper .right-side > .content {
  padding: 2rem;
  position: relative;
}

@media screen and (max-width: 767px) {
  .wrapper {
    display: block;
  }
}
.page-header {
  justify-content: space-between;
  display: flex;
  margin-bottom: 24px;
}
.page-header .page-title {
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  display: flex;
  align-items: center;
}
.page-header .page-title a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-header .page-title a svg {
  width: 26px;
  margin-right: 10px;
}
.page-header .header-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

ul#ant-sidemenu {
  border: none;
  background-color: transparent;
}
ul#ant-sidemenu .ant-menu-item {
  padding-left: 10px !important;
  height: 26px;
}
ul#ant-sidemenu .ant-menu-item svg {
  width: 14px !important;
}
ul#ant-sidemenu .ant-menu-item .ant-menu-title-content {
  font-size: 14px;
}
ul#ant-sidemenu .ant-menu-sub {
  background-color: transparent;
}
ul#ant-sidemenu .ant-menu-submenu .ant-menu-item {
  padding-left: 32px !important;
}
ul#ant-sidemenu .ant-menu-submenu .ant-menu-submenu-title {
  height: 26px;
  padding-left: 10px !important;
}
ul#ant-sidemenu .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow {
  color: #a9a8a8 !important;
}

.side-bar {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.side-bar li {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.side-bar li:last-child {
  margin-bottom: 0;
}
.side-bar li a {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
}
.side-bar li a.active i, .side-bar li a.fouse i, .side-bar li a.hover i {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 6px;
}
.side-bar li a svg {
  width: 20px;
}
.side-bar li a i {
  display: block;
  padding: 2px 12px;
}
.side-bar li a i:hover, .side-bar li a i.active {
  background-color: rgba(0, 0, 0, 0.8);
  color: #2046CF;
  border-radius: 6px;
  text-align: center;
}
.side-bar li a span {
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  margin-top: 2px;
}
.side-bar i {
  margin-right: 0px;
}
.side-bar .sidebar-label {
  flex-grow: 1;
}
.side-bar .submenu {
  display: none;
  background: #f0f0f0;
  padding: 10px;
}
.side-bar .submenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.toggle-input {
  display: none;
}

.aa_box_title {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #F0F4F9;
}
.aa_box_title p {
  color: rgba(0, 0, 0, 0.6);
}


