.card {
    background-color: #FFF;
    border-radius: $radius;

    &.card-default {
        padding: 30px;
    }
}
.ant-card{
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 16px 0px rgba(0, 0, 0, 0.02);
    
}