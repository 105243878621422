.auth-wrap {
    display: flex;
    background-color: #FFF;
    .left-col {
        width: 400px;
        flex-shrink: 0;
        background-color: #000;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        h3 {
            color: #FFF;
            font-weight: 400;
            max-width: 300px;
            margin: 40px auto 0 auto;
        }
    }
    .right-col {
        padding: 50px;
        width: 100%;
        .header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            p {
                margin: 0 10px 0 0;
            }
        }
    }
}

.auth-form-wrap {
    max-width: 500px;
    margin: auto;
}

.header-auth {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}