// Display Utilities
.d-none {
    display: none !important;
  }
  
  .d-inline {
    display: inline !important;
  }
  
  .d-inline-block {
    display: inline-block !important;
  }
  
  .d-block {
    display: block !important;
  }
  
  .d-table {
    display: table !important;
  }
  
  .d-table-row {
    display: table-row !important;
  }
  
  .d-table-cell {
    display: table-cell !important;
  }
  
  .d-flex {
    display: flex !important;
  }
  
  .d-inline-flex {
    display: inline-flex !important;
  }
  
  // Visibility Utilities
  .invisible {
    visibility: hidden !important;
  }
  
  .visible {
    visibility: visible !important;
  }
  
  // Responsive Display Utilities
  @media (max-width: 575px) {
    .d-sm-none {
      display: none !important;
    }
  
    .d-sm-inline {
      display: inline !important;
    }
  
    .d-sm-inline-block {
      display: inline-block !important;
    }
  
    .d-sm-block {
      display: block !important;
    }
  
    .d-sm-table {
      display: table !important;
    }
  
    .d-sm-table-row {
      display: table-row !important;
    }
  
    .d-sm-table-cell {
      display: table-cell !important;
    }
  
    .d-sm-flex {
      display: flex !important;
    }
  
    .d-sm-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 576px) {
    .d-md-none {
      display: none !important;
    }
  
    .d-md-inline {
      display: inline !important;
    }
  
    .d-md-inline-block {
      display: inline-block !important;
    }
  
    .d-md-block {
      display: block !important;
    }
  
    .d-md-table {
      display: table !important;
    }
  
    .d-md-table-row {
      display: table-row !important;
    }
  
    .d-md-table-cell {
      display: table-cell !important;
    }
  
    .d-md-flex {
      display: flex !important;
    }
  
    .d-md-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 768px) {
    .d-lg-none {
      display: none !important;
    }
  
    .d-lg-inline {
      display: inline !important;
    }
  
    .d-lg-inline-block {
      display: inline-block !important;
    }
  
    .d-lg-block {
      display: block !important;
    }
  
    .d-lg-table {
      display: table !important;
    }
  
    .d-lg-table-row {
      display: table-row !important;
    }
  
    .d-lg-table-cell {
      display: table-cell !important;
    }
  
    .d-lg-flex {
      display: flex !important;
    }
  
    .d-lg-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 992px) {
    .d-xl-none {
      display: none !important;
    }
  
    .d-xl-inline {
      display: inline !important;
    }
  
    .d-xl-inline-block {
      display: inline-block !important;
    }
  
    .d-xl-block {
      display: block !important;
    }
  
    .d-xl-table {
      display: table !important;
    }
  
    .d-xl-table-row {
      display: table-row !important;
    }
  
    .d-xl-table-cell {
      display: table-cell !important;
    }
  
    .d-xl-flex {
      display: flex !important;
    }
  
    .d-xl-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 1200px) {
    .d-xxl-none {
      display: none !important;
    }
  
    .d-xxl-inline {
      display: inline !important;
    }
  
    .d-xxl-inline-block {
      display: inline-block !important;
    }
  
    .d-xxl-block {
      display: block !important;
    }
  
    .d-xxl-table {
      display: table !important;
    }
  
    .d-xxl-table-row {
      display: table-row !important;
    }
  
    .d-xxl-table-cell {
      display: table-cell !important;
    }
  
    .d-xxl-flex {
      display: flex !important;
    }
  
    .d-xxl-inline-flex {
      display: inline-flex !important;
    }
  }
  