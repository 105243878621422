$cell-padding: 10px 16px;
$cell-padding-compact: 8px 10px;


.ant-table-custom {

    &.table-compact {
        table {
            th {
                padding: $cell-padding-compact;
            }
            td {
                padding: $cell-padding-compact;
            }

        }
    }

    table {
        table-layout: auto;
        width: 100%;
        text-align: start;
        border-radius: 14px 14px 0 0;
        border-collapse: separate;
        border-spacing: 0;



        th {
            position: relative;
            color: rgba(0, 0, 0, 0.88);
            font-weight: 600;
            text-align: start;
            background: #fafafa;
            border-bottom: 1px solid #f0f0f0;
            padding: $cell-padding;
            overflow-wrap: break-word;
            font-size: 14px;

            &::before {
                position: absolute;
                top: 50%;
                inset-inline-end: 0;
                width: 1px;
                height: 1.6em;
                background-color: #f0f0f0;
                transform: translateY(-50%);
                transition: background-color 0.2s;
                content: "";
            }
        }

        td {
            position: relative;
            color: rgba(0, 0, 0, 0.88);
            text-align: start;
            background: #FFF;
            border-bottom: 1px solid #f0f0f0;
            padding: $cell-padding;
            overflow-wrap: break-word;
            font-size: 14px;
        }

        thead {
            tr {
                &:first-child >*:first-child {
                    border-start-start-radius: 14px;
                }
                &:first-child >*:last-child {
                    border-start-end-radius: 14px;
                }
                
            }
        }

       
    }
}

.table-form {
    input {
        border: none;
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-input {
        padding: 4px 0;
    }
    .ant-select-selector {
        padding: 0 !important;
    }

    button {
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        svg {
            width: 24px;
        }
    }
}

.delete {
    svg {
        width: 18px;
        cursor: pointer;
    }
}

.table-actions {
    display: flex;
    a {
        margin-right: 14px;
        color: $black;
    }

    svg {
        width: 16px;
        height: 16px;
    }
}